<script lang="ts">
  // svelte imports
  import { onMount } from "svelte";
  import { quintOut } from "svelte/easing";
  import { slide } from "svelte/transition";
  // lib imports
  import Image from "@/components/ui/image/image.svelte";
  import Button from "./ui/button/button.svelte";
  import Typography from "./ui/typography/typography.svelte";
  // image imports
  import CookieIcon from "@/images/cookie.svg";

  let render = false;
  onMount(async () => {
    render = JSON.parse(localStorage.getItem("show-cookies-banner") ?? "true");
  });

  const handleChoice = (accepted: "true" | "false") => {
    localStorage.setItem("show-cookies-banner", "false");
    localStorage.setItem("consentGranted", accepted);
    render = false;
  };
</script>

{#if render}
  <div
    transition:slide={{
      duration: 700,
      easing: quintOut,
      axis: "y"
    }}
    class="fixed bottom-0 left-0 z-[9999999999999999999] flex min-h-[80px] w-full flex-col items-center justify-center gap-6 border-t border-x-dark-gray bg-theme-black p-4 lg:flex-row">
    <div class="flex items-center gap-6">
      <Image src={CookieIcon} class="h-10 w-10" />
      <div>
        <Typography class="inline-block !text-base !font-normal" color="muted">
          <span class="text-theme-white"
            >We use cookies in the delivery of our services.</span>
          To learn about the cookies we use and information about your <br />
          preferences and opt-out choices,
          <a href="/privacy-policy" class="text-theme-white underline">
            please click here</a
          >. By using our platform you agree to our use of cookies.
        </Typography>
      </div>
    </div>
    <div class="flex items-center gap-3">
      <Button on:click={() => handleChoice("true")} variant="secondary"
        >Reject</Button>
      <Button on:click={() => handleChoice("false")} variant="default"
        >Accept</Button>
    </div>
  </div>
{/if}
